// polyfill
import 'babel-polyfill'
// Vue
import Vue from 'vue'
import i18n from './i18n'
import App from './App'
// 核心插件
import d2Admin from '@/plugin/d2admin'
// store
import store from '@/store/index'
// 菜单和路由设置
import router from './router'
/* 高德地图的引入 */
import VueAMap from 'vue-amap'
/* vue-print的引入 */
import Print from 'vue-print-nb'

// ElementUI及D2Crud引用
import formCreate, { maker } from '@form-create/element-ui'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import D2Crud from '@d2-projects/d2-crud'
// import '@/drag'
import '@/utils/dialog.js'
// echarts引入
import echarts from 'echarts'
// 剪切板功能
import VueClipboard from 'vue-clipboard2'
// pl-table
// import plTable from 'pl-table'
//
// import 'pl-table/themes/index.css' // 引入样式（必须引入)，vuecli3.0不需要配置，cli2.0请查看webpack是否配置了url-loader对woff，ttf文件的引用,不配置会报错哦
//
// import 'pl-table/themes/plTableStyle.css' // 默认表格样式很丑 引入这个样式就可以好看啦（如果你不喜欢这个样式，就不要引入，不引入就跟ele表格样式一样）
// 上下菜单功能
import VueContextMenu from 'vue-contextmenu'
// 引入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 引入生成二维码
import VueQriously from 'vue-qriously'

// 核心插件
Vue.use(formCreate, { maker })
Vue.use(d2Admin, { store })
Vue.use(ElementUI)
Vue.use(D2Crud)
Vue.use(VueAMap)
Vue.use(Print)
Vue.use(VueClipboard)
Vue.use(VueContextMenu)
// Vue.use(plTable)
Vue.prototype.$echarts = echarts
Vue.use(VueQuillEditor)
Vue.use(VueQriously)

/* 高德地图的引入 */
VueAMap.initAMapApiLoader({
  // key: '646f14ffb3994bf0de3d9a21632a6798',
  key: '86d481c9c0d7c9f755431600e1b5eb9e',
  plugin: [
    'AMap.Autocomplete', // 输入提示插件
    'AMap.PlaceSearch', // POI搜索插件
    'AMap.Scale', // 右下角缩略图插件 比例尺
    'AMap.OverView', // 地图鹰眼插件
    'AMap.ToolBar', // 地图工具条
    'AMap.MapType', // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    'AMap.PolyEditor', // 编辑 折线多，边形
    'AMap.CircleEditor', // 圆形编辑器插件
    'AMap.Geocoder', // 地图编码
    'AMap.AMapManager',
    'AMap.Marker',
    'AMap.Geolocation' // 定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  uiVersion: '1.0', // ui库版本，不配置不加载,
  v: '1.4.4'
})
// 高德的安全密钥
window._AMapSecurityConfig = {
  securityJsCode: '5b5ad179f534e8e0a563ac5f64b2e22e'
}
// 若是没有开启Devtools工具，在开发环境中开启，在生产环境中关闭
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true
} else {
  Vue.config.devtools = false
}

// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created () {
  },
  mounted () {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow')
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('d2admin/account/load')
    // 获取并记录用户 UA
    this.$store.commit('d2admin/ua/get')
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen')
  }
}).$mount('#app')
